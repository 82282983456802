<template>

    <form-validation
        :attributes="formAttributes"
        :validation="formValidation"
        :state="formState"
        @validation="processValidation"
        @state="processState"
    ></form-validation>

</template>


<script>
    export default {

        components: {
            // 'form-validation': require('./Form/Validation.vue'),
            'form-validation': () => import(
                /* webpackChunkName: "Validation" */ 
                /* webpackPreload: true */ 
                './Form/Validation.vue'
            ),
        },
        

        //To do: make a component called form-diagnostics, that takes all the things I care about (such as coordinates of the latest event, etc..) and puts them into a form or a table that is updated as I work on the form

        //another todo: make a component called attributes-form that lets me enter a form's attributes into a form, and then renders the form.

        //note somethign not on my todo, whcih i already did somewhere .. is to make a function which looks at a form attributes object and outputs a new form that lets one edit the object .. I imagine this will be useful at some point, although the current version is not useful because I don't have any way to go from the output of this form to a new form attributes object, and anyway I would need to have a much more complicated validation set up for this to be useful or effective.  ok, that code is not in this component file .. it might be in profile.vue

        //todo first: fix the directory structure to allow for validation-string and validation-array, and validation-collection, etc..

        props: ['attributes', 'validation', 'state', 'value',],

        //there is a lot more this form class could do, involving submitting things to the server and such. Right now it just does the very bare minimum, closes the one-way data loop.
        
        data() {
            return {
                //the type is the key and the component is the value
                validationComponentMap: {
                    input: 'string',
                    textarea: 'string',
                    checkbox: 'string',
                    // 'pre',
                    // 'code',
                    button: 'button',
                    selectOne: 'object',
                    selectMany: 'collection',
                    forms: 'forms',
                    menu: 'object',
                    index: 'none',
                    // 'atomic',
                }, 

                validationComponentNames: [
                    'input',
                    'textarea',
                    'checkbox',
                    'pre',
                    'code',
                    'button',
                    'select',
                    'forms',
                    'menu',
                    'index',
                    'atomic',
                ],

                localAttributes: null,
                // holds a copy of the incoming attributes and changes more regularly because vue is haphazardly changing or not changing the actula attributes for no perceivable reason.

                //holds the most recently received validation event from below
                localValidation: null,

                //holds the most recently received state event from below
                localState: null,

                //     /*
                //     4 verbs I need to have in my vocabulary .. and should start to use consistently
                //     1. (down) accepting props from my parent -- accept parentProp
                //     2. (down) binding props to my child -- bind childProp
                //     3. (up) processing events from my child -- process childEvent
                //     4. (up) emitting events to my parent -- emit parentEvent

                //     */
            };
        },


        created() {
            this.localAttributes = Object.assign({},this.attributes);
        },

        //todo-last: consider changing the name of element to 'input' and don't use input for string text inputs anymore .. Using input ala html style is not a great naming convention, because it subsumes checkboxes and dates and other things that require their own component .. In addition to this, the word input can be interpreted more generically as something that listens for sensory-events such as key presses and mouse operations. I may like input better than element now, given that I am freeing this word from its ordinary html context .. and using terms like input.event and input.value will make sense for textareas and selects and even more complicated sensory inputs. In retrospect, 'Element' was about the best I could have done given that input was being used for something else, but it was never such a great or descriptiive word to use .. and in the long run I think it is better to change it.

        watch: {

            //this seems to be working without errors, but it gets called too frequently, especially on forms components, where I don't completely understand the side effects. 
            serializedAttributes: function(newValue, oldValue) {
                console.log('triggered attributes watcher');
                if (oldValue !== newValue) {
                    console.log('actually resetting localValidation');
                    console.log([oldValue, newValue]);
                    console.log('component: ');
                    console.log(this.attributes.component);
                    this.localValidation = this.validationFromValueAndLocals(this.formAttributes, this.value, this.localValue, this.localValidation);
                    this.localState = this.addStateCoordinates(this.formAttributes, this.localState);
                    this.localAttributes = JSON.parse(newValue);
                }
            },
        },

        computed: {


            serializedAttributes: function() {
                return JSON.stringify(this.attributes);
            },

            //merge all nested child objects into their associated collection and add coordinates to the attributes
            formAttributes: function() {
                return this.addCoordinates(this.mergeAttributesCollections(this.localAttributes, this.localAttributes.child), [], []);
            },


            //valueFrom works on null now, so this should be ok
            localValue: function() {
                return this.valueFrom(this.formAttributes, this.localValidation);
            },


            //this tells the form whether to use its parent's props for state and value.  The default is to use the local data properties instead.
            formIsManaged: function() {
                return this.state && typeof this.state === 'object' && this.state.managed === true;
            },


            //the basic pre-modification state object for the form -- depends on whether the form is managed
            baseState: function() {
                return this.formIsManaged ? this.state : this.localState;
            },

            //returns whether the form coordinates have been added to the base state object
            baseStateHasCoordinates: function() {
                return this.baseState && typeof this.baseState === 'object' && this.baseState.hasOwnProperty('valueCoordinates');
            },

            //the base state with coordinates added if they aren't already there.
            formState: function() {
                return this.baseStateHasCoordinates ? this.baseState : this.addStateCoordinates(this.formAttributes, this.baseState);
            },
            
             
            //tells whether the form should accept / interpret its validation prop, rather than its value prop.
            formAcceptsValidation: function() {
                return this.state && typeof this.state === 'object' && this.state.source === 'validation';
            },

            //return a validation object using the latest value prop and any existing local data. Guaranteed to return the correct structure for a validation object, so it can be used as a default in other cases.
            valueValidation: function() {
                return this.validationFromValueAndLocals(this.formAttributes, this.value, this.localValue, this.localValidation);
            },

            //If the form is not managed, use the localValidation
            //If the form is managed and it accepts validation, use the validation prop.
            //If the form is managed and it accepts a value, convert the value prop to a validation.
            baseValidation: function() {
                if (! this.formIsManaged) {
                    return this.localValidation;
                }
                
                if (this.formAcceptsValidation) {
                    return this.validation;
                }
                    
                return this.valueValidation;
            },

            //use the base validation unless it is null, then use the valueValidation.
            formValidation: function() {
                return this.baseValidation || this.valueValidation;
            },

            //this tells the form not to emit any more events. It's intended mainly for testing and as a temporary braking lever for forms that are emitting too many events or otherwise malfunctioning.
            formIsSilent: function() {
                return this.state && typeof this.state === 'object' && this.state.silent === true;
            },

        },


        methods: {           

            //new note: this function was written back when form was not called recursively, and the forms component called the validation component directly. Now that Form.vue is called recursively, I find it unlikely that this function works correctly. I was never really using it for anything particularly useful, just that it was nice to diagnose forms using positional coordinates. In any case, it seems like if we wanted a coordinate system we could get one more simply by having form pay attention to any coordinates that are coming in on the parent attributes object and only handline the current layer .. i.e. not diving into the nesting structure of attributes. It's also worth thinking about whether this structure should change if the type that is written into the attributes is an object, which previously was not supported. This version really only considers value and collection. But now I would like to support objects as well, which I think would likely be coordinated differently, if they need coordinates at all.

            //this function took a while to get right so please don't delete it.
            addCoordinates(attributes, formCoordinates, valueCoordinates) {
                let locatedAttributes = Object.assign({}, attributes, {
                    formCoordinates:  formCoordinates,
                    valueCoordinates: valueCoordinates,
                    hasValue: this.isValidationComponent(attributes),
                });
                
                if(!Array.isArray(attributes.collection)) {
                    return locatedAttributes;
                }

                var valueIndex = -1; // the value index only increments when childAttributes is a validation component.

                return Object.assign({}, locatedAttributes, {
                    collection: attributes.collection.map(function(childAttributes, childAttributesIndex) {
                        
                        let isValidation = this.isValidationComponent(childAttributes);
                        valueIndex = isValidation ? valueIndex + 1 : valueIndex;

                        return this.addCoordinates(childAttributes, formCoordinates.concat(childAttributesIndex), valueCoordinates.concat(valueIndex));
                    }, this),
                });

            },
            
            //new note: this functionality shoudl be handled better in forms

            //this function returns a new set of form attributes with child properties merged into collection object properties at each non-leaf node. 
            mergeAttributesCollections(attributes, attributesChild = null) {
                //If there is no collection, return the attributes
                if(!Array.isArray(attributes.collection)) {
                    return attributes;
                }

                //deal with all child data for this attributes, e.g. including nested child.child.child properties in attributesChild
                let newCollection = attributesChild ? attributes.collection.map(function(childAttributes) {
                    return this.merge(this.mergeAttributesCollections(childAttributes, attributesChild.child), attributesChild);
                }, this) : attributes.collection;

                //now address the children of the collection, which may have their own nested child data.
                return Object.assign({}, attributes, {
                    //add the name of the component here, since it could be left off as a default
                    component: 'forms',
                    collection: newCollection.map(function(childAttributes) {
                        return this.mergeAttributesCollections(childAttributes, childAttributes.child);
                    }, this),
                });
            },            

            /////////////////////////////
            //returns true iff a and b if either they have the same items or if they are the same item
            sameItems(a, b) {
                if (a === b) { // short circuit to true if the items are equal
                    return true;
                }
                if(!a || !b || typeof a !== typeof b) { // short circuit to false if the items are falsy (and unequal) or have different types
                    return false;
                }
                
                if(Array.isArray(a) && Array.isArray(b)) { //for two arrays, check the length and test for the same values in the proper order
                    return a.length === b.length && a.every(function(aChild, aChildIndex) {
                        return this.sameItems(aChild, b[aChildIndex]);
                    }, this);
                }

                if(typeof a === 'object') { //if we get here, b is also a non-null object
                    return Object.keys(a).every(function(aChildKey){
                        return this.sameItems(a[aChildKey],b[aChildKey]); //a and b have the same values for every key of a
                    }, this) && Object.keys(b).every(function(bChildKey){
                        return a.hasOwnProperty(bChildKey); //a has all the keys of b
                    }, this);
                }
                
                return false; //consider doing something more for funciton types? I would have to do more research to investigate how or why it would be common that two functions would be the same but not report equality in the first check.

            },


            ////////////////////////These next three functions go together
            isObject(item) {
                return item && typeof item === 'object' && !Array.isArray(item);
            },

            objectMerge(parent, child) {

                let merged = Object.assign({}, parent);

                Object.keys(child).forEach(key => {
                    let parentValue = merged.hasOwnProperty(key) ? merged[key] : {};
                    merged[key] = this.merge(parentValue, child[key]);
                    // console.log('key', key, 'parentValue', parentValue, 'childValue', child[key], 'result', merged[key]);                  
                }, this);

                return merged;
            },

            merge(parent, child) {

                if (typeof child === 'undefined') {
                    // console.log('case1');
                    return parent;
                }

                if (!this.isObject(parent) && !Array.isArray(parent)) { //what to do in this case requires a judgment .. should be validated with more experience.
                    // console.log('case2');
                    return child;
                }

                // now we can assume parent is an object or an array, and that child is defined
                if (Array.isArray(parent)) {
                    if (Array.isArray(child)) {
                        // console.log('case3');
                        return parent.concat(child);
                    }
                    // console.log('case4');
                    return child;

                }

                //now we can assume parent is an object
                if (this.isObject(child)) {
                    // console.log('case5');
                    return this.objectMerge(parent, child);
                }
                // console.log('case6');
                return child;
            },
            ////////////////////////

            // testMerge(parent, child) {
            //     console.log('parent', parent);
            //     console.log('child', child);
            //     console.log('merged', this.merge(parent, child));
            // },

            isValidationComponent(attributes) {
                return (this.validationComponentNames.includes(attributes.component));
                // return (['input', 'text', 'button', 'select', 'forms', 'menu', 'index',].includes(attributes.component));
            },

            addStateCoordinates(attributes, state) {
                let stateObject = (state && typeof state === 'object') ? state : {};
                if(!Array.isArray(attributes.collection)) {
                    return Object.assign({}, stateObject, {
                        formCoordinates: attributes.formCoordinates,
                        valueCoordinates: attributes.valueCoordinates,
                        hasValue: attributes.hasValue,
                    });
                }
                let stateObjectCollection = Array.isArray(stateObject.collection) ? stateObject.collection : [];
                return Object.assign({}, stateObject, {
                    formCoordinates: attributes.formCoordinates,
                    valueCoordinates: attributes.valueCoordinates,
                    hasValue: attributes.hasValue,
                    collection: attributes.collection.map(function(childAttributes, childAttributesIndex) {
                        return this.addStateCoordinates(childAttributes, stateObjectCollection[childAttributesIndex]);
                    }, this),
                });
            },


            //this function generates a valid validation object based mainly on a parent value prop, using any available local information.
            validationFromValueAndLocals(attributes, parentValue, localValue, localValidation) {
                //if the localValidation is null or undefined, build an empty validation from the parentValue.
                if(! localValidation) {
                    return this.validationFromValue(attributes, parentValue);
                } 
                
                //when the parentValue is the same as the localValue, we can return the localValidation. This should be the common case.
                if(this.sameItems(parentValue, localValue)) {
                    return localValidation;
                }
                
                //if the parentValue doesn't match the localValue, build a new validation using the parentValue.
                if (!Array.isArray(attributes.collection)) {
                    return {
                        value: parentValue,
                    };
                }
                //for collections, we use the attributes structure to guide the assignment, and use null whenever something doesn't parse 
                return {
                    value: attributes.collection.filter(this.isValidationComponent).map(function(childAttributes, childAttributesIndex) {
                        return this.validationFromValueAndLocals(childAttributes, parentValue ? parentValue[childAttributesIndex]: null, localValue ? localValue[childAttributesIndex] : null, localValidation.value ? localValidation.value[childAttributesIndex] : null);
                    }, this),
                };

            },


            //construct a validation object only from valid parts of the value. Use the attributes as an authoritative structure.
            validationFromValue(attributes, value) {
                return !Array.isArray(attributes.collection) ? { value: value } : {
                    value: attributes.collection.filter(this.isValidationComponent).map(function(childAttributes, childAttributesIndex) {
                        return this.validationFromValue(childAttributes, Array.isArray(value) ? value[childAttributesIndex] : null);
                    }, this),
                };
            },

            //This function extracts a nested array containing only the present values of a validation object.
            valueFrom(attributes, validation) {
                let validationObject = validation && typeof validation ==='object' ? validation : {};
                if (!Array.isArray(attributes.collection)) {
                    return validationObject.value;
                }
                let validationObjectValue = Array.isArray(validationObject.value) ?  validationObject.value : [];
                return attributes.collection.filter(this.isValidationComponent).map(function(childAttributes, childAttributesIndex) {
                    return this.valueFrom(childAttributes, validationObjectValue[childAttributesIndex]);
                }, this);

            },
       
            /*
            As far as I remember I am not using any of these 'At' functions .. but they seemed like a good idea to have and it's a cool use of the reduce function
            ------------------------
            */

            //Return the attributes for the node at the given attributes coordinates
            attributesAt(attributes, formCoordinates) {
                return formCoordinates.reduce(function(childAttributes, formCoordinate) {
                    return childAttributes.collection ? childAttributes.collection[formCoordinate] : {};
                }, attributes);
            },

            //recursively walk the attributes tree keeping only the ones having validation components
            validationAttributes(attributes) {
                return !Array.isArray(attributes.collection) ? attributes : attributes.collection.filter(this.isValidationComponent).map(this.validationAttributes);
            },

            //Return the attributes for the unique validation node that is located at the given value coordinates
            attributesAtValue(attributes, valueCoordinates) {
                return this.attributesAt(this.validationAttributes(attributes), valueCoordinates);
            },

            stateAt(state, formCoordinates) {
                return formCoordinates.reduce(function(childState, formCoordinate) {
                    return Array.isArray(childState.collection) ? childState.collection[formCoordinate] : {};
                }, state);
            },

            stateAtValue(state, attributes, valueCoordinates) {
                return false; // figure this out if/when I need it for something
            },

            //Return the value for the node at the given value coordinates
            valueAt(value, valueCoordinates) {
                return valueCoordinates.reduce(function(childValue, coordinate) {
                    return childValue ? childValue[coordinate] : [];
                }, value);
            },
            /*---------------------*/


            processValidation(validation) {
                this.localValidation = validation;

                console.log('received validation event in Form.vue');
                console.log(this.localValidation.element.event.type);
                console.log(this.localValidation, this.localValue);
                console.log('my child is ' + this.formAttributes.component);

                // if (! this.formIsSilent && this.localValidation.element.event.type !== 'boot') {
                //     this.$emit('validation', this.localValidation, this.localValue);
                // }                
                if (! this.formIsSilent) {
                    this.$emit('validation', this.localValidation, this.localValue);
                }
            },

            processState(state) {
                this.localState = state;
                console.log('state event in form.vue');
                console.log(state);
                if (! this.formIsSilent ) {
                    this.$emit('state', state);
                    console.log('emitting state in form.vue');
                }

            },

        },

    }

</script>
