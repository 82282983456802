
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//don't get rid of this .. it just loads the bootstrap.js file which I am also editing..
require('./bootstrap');

//let's try this..
require('typeface-nunito');

//testing this..
// import materialIcons from 'material-design-icons/iconfont/material-icons.css';


// const Vue = import(/* webpackChunkName: "chunk-foo" */ 'vue');

import Vue from 'vue';
window.Vue = Vue; // not sure what the framework uses this for.. I guess so that we can use things like this.$vue.set?

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


//global components
import Form from './components/Form.vue';
Vue.component('app-form', Form)


//setting up Vue router
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import routes from './routes';
const router = new VueRouter({
    mode: 'history',
    routes,
});


import App from './components/App.vue';

new Vue({
    el: '#app',

    components: {
    	App,
    },

    render: function(createElement) {
        return createElement(App);
    },

    router
});
