<style lang="scss">
    @import "../../sass/app.scss";
    // html {
    //     box-sizing: border-box;
    // }

    // *, *:before, *:after {
    //     box-sizing: inherit;
    // }

    .app-layout {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }


    html {
        box-sizing: border-box;
        // height: 100vh;
        // overflow: hidden;
    }

    *, *:before, *:after {
        box-sizing: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        // box-sizing: inherit;
    }

    body {
        // overflow: hidden;
        margin: 0;
        font-family: "Raleway", sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.6;
        text-align: left;
        background-color: #636463;
        color: #212529;
    }


// do I really want to hard code all of this styling into the main entry app that is loaded into the vue framework? If I wanted to have a different layout, how would I structure this differently? It looks like th app-layout component could accept a user object and a message. Perhaps eventually we could include the entry link for the user in the response? And then we would have all we need to do the app starting from a single entry api. The entry api would just tell the app what to do and we would grow it from there.
</style>

<template>
    <div class="app-layout">

        <app-header 
            :user="user"
            @route="updateRoute"
        ></app-header>

        <router-view name="content"
            :user="user"
            :message="message"
            @login="login"
            @logout="logout"
            @profile="getUser"
            @message="updateMessage"
            @route="updateRoute"
            @closeMessage="closeMessage"
        ></router-view>
       
        <app-footer 
            :user="user" 
            @logout="logout"
        ></app-footer>

    </div>
</template>

<script>
    export default {

        components: {
            'app-header': () => import(
                /* webpackChunkName: "AppHeader" */ 
                /* webpackPreload: true */ 
                './app/Header.vue'
            ),
            'app-footer':  () => import(
                /* webpackChunkName: "AppFooter" */ 
                /* webpackPreload: true */ 
                './app/Footer.vue'
            ),
        },

        data() {
            return {
                timer: null,
                user: {},
                message: {}, // message is included here because we need messages for authorizations and those are only done here ..
            };
        },
        
        created() {
            let authenticationString = localStorage.getItem('authentication');
            if(authenticationString) {
                let authentication = JSON.parse(authenticationString);
                let timeUntilExpires = (new Date(authentication.expiresAt)).getTime() - (new Date()).getTime();

                //if the token has expired, log out the user
                if (timeUntilExpires < 0) {
                    this.logout();
                    return;
                }

                //add the token to the outgoing request headers
                this.setTokenAuthentication(authentication.token);
                
                //load the current user
                this.getUser();

                //set a timer to log out the user when the token expires.
                //this should be more sophisticated, possibly using refresh tokens, etc..
                this.timer = setTimeout(this.logout, timeUntilExpires);
            }
        },

        methods: {

            setTokenAuthentication(token) {
                axios.interceptors.request.use((config) => {
                    config.headers['Authorization'] = 'Bearer ' + token;
                    return config;
                });
            },

            unsetTokenAuthentication() {
                axios.interceptors.request.use((config) => {
                    config.headers['Authorization'] = undefined;
                    return config;
                });
            },
/////

            redirectUserTo(routeName, message = {}) {
                this.$router.push({
                    name: routeName,
                    params: {
                        message: message,
                    },
                });
            },

//FIX THIS .. do not load the user now .. possibly check if the token is expired .. possibly set a timeout function which will wake up and log out the user when it expires.

            // logoutWhenTokenExpires(expiresAt) {
            //     let timeUntilExpires = expiresAt.getTime() - (new Date()).getTime();
            //     return setTimeout(this.logout, timeUntilExpires);
            // },

            getUser() {
                console.log('here in the getUser function..');
                let userUrl = '/api/user';
                // axios.get(userUrl, {
                //     headers: {'Authorization': 'Bearer ' + token}
                // }).then(response => {                
                return axios.get(userUrl).then(response => {
                    console.log('getting user');
                    console.log(response.data);
                    this.user = response.data;
                    this.message = {
                        class: 'success',
                        body: 'Logged in as ' + this.user.name,
                    };
                    // this.redirectUserTo('Dashboard', this.message);
                }).catch(error => {
                    console.log('request to get user data failed');
                    console.log(error.response);
                    this.message = {
                        class: 'danger',
                        // body: 'The login failed -- unable to load user profile',
                        body: error.response.data.message,
                    };
                });
            },

            //note: when I start having a lot of different apps, I will want to assign each one of them a separate client id, and need to figure out how to do that when someone downloads this app file. At the moment, everythign is loading the same stuff. So ok fine .. I can distinguish some things on the basis of tokens, but not this login function. hmm.. maybe it is ok because every app needs a login and it's not very important to hide this key anyway as it just allows making requests to the login api. There are only so many ways I can protect this.. ultimately once someone downloads this js code, they will in principle be able to deduce how to query the login api. ACtually, this is not an important concern right now .. I only need a new id if I want to have a separate login system or user repository. At the moment I am envisioning all of my apps to use the same user repository. If that condition fails, then it would make more sense to just serve a different App.vue file for that particular app. It could still share the source code of my local development environment, but would just have a different entry. In that case, I would probably just specify that in my routes file? passing a parameter in to say which App component to use. I could also do it in app.js, where the App component is inserted into the DOM dynamically.

            login(username, password) {

                console.log('here in login function in App.vue');

                var tokenUrl = '/oauth/token';
                // var userUrl = '/api/user';

                //I should extract the client secret to a non-git-indexed file
                var request_data = {
                    grant_type: 'password',
                    client_id: '1',
                    client_secret: '6wSCvW5XVl8KYWcEQax9eKRjXSkoQ70jm8tFm4kJ',
                    username: username,
                    password: password,
                    scope: '*',
                };

                axios.post( 
                    tokenUrl, request_data 
                ).then(response => {
                    console.log('response');
                    console.log(response.data);
                    let token = response.data.access_token;
                    

                    ////////NEW TOKEN MANAGEMENT
                    //I have about a year before I need to deal with expiring tokens .. by then all of this should be done correctly
                    //PS now is Nov 29, 2019
                    //No it's not. Because today is August 6, 2019, and this comment was already there. Maybe it was Nov 29 2018?
                    let expiresIn = response.data.expires_in;

                    localStorage.setItem('authentication', JSON.stringify({
                        token: token,
                        expiresIn: expiresIn,
                        expiresAt: new Date(Date.now() + 1000 * expiresIn),
                    }));
                    ///////////////////////////

                    this.setTokenAuthentication(token);
                    this.getUser().then(response => {
                        this.redirectUserTo('Dashboard', this.message);
                    });
                }).catch(error => {
                    console.log('request to get token failed');
                    console.log(error.response);
                    this.message = {
                        class: 'danger',
                        body: error.response.data.message,
                    };
                });
            },

            logout() {
                localStorage.clear();
                this.unsetTokenAuthentication();
                this.redirectUserTo('Welcome');
                location.reload(true);
            },

            updateMessage(message) {
                this.message = message;
                //should also log the message here.
            },

            updateRoute(route) {
                this.$router.push(route);
            },

            closeMessage() {
                console.log('received closeMessage event in App.vue');
                this.$set(this.message, 'body', '');
            },
        },

    }
</script>
