export default [


/*
Some discussion here .. currently I have only 4 specially-defined routes. One for login, one for the welcome page, 
one for the dashboard and one for the profile page. I guess login is a special-enough case that it warrants 
its own separate route in teh source code here. Similarly dashboard and welcome are sufficiently different and dashboard 
requires an auth check.  For Profile I'm not sure.  

We could consider the user profile to be a separate thing from each app, kinda like something that lives above all the apps.  
I think we could also consider having different versions of a profile for each app also.  Ok, so maybe the dashboard is where you
go to get things done .. a place where your tasks are already set up and configured for you to be productive .. And then profile 
would be a place that you go to configure your tasks.

ok but most applications would call this somehting more like preferences or settings rather than profile.

Whatever I think this profile can be a place where the user goes to
 - browse / install / purchase new apps
 - view / configure existing apps
 - update personal information

 The dashboard is a place the user goes to 
 - select an app to use.
 - use an app

 For the system to be doing things the right way, the set of apps and enough info to load each app needs to be defined at the server level. So then both 
 the profile and the dashboard need to be able to access the user apps object.  This also says that the basic app information should 
 probably be loaded along with the user object and saved to localstorage .. So when a user purchases a new app, it should save the data
 about the availability of the new app to the browser. Of course the api should also check the auth on every request so that people cannot
 hack the system by hacking into localstorage.

For sustainability, it is almost surely best to store as little information as possible in the raw data structure which uniquely identifies 
an app model.  Ideally this should be a single integer, which would identify both the app and the model/task/form.

ok but in actuality I need several pieces of information for such a task. 
1. the name that the user sees in the menu to click on
2. the api link that actually retrieves the form
3. the vue component that renders the content (thins might just be app-form, but also can allow custom pages for the time being, 
just so that I can have fast creation of interfaces while the interface-builder app is not fully funcitonal. fine but u can already do 
this within app-form .. just add a subcomponet under monitor, and use it like the game-theory modeling component.)
4. the link that appears in the browser history so that the back and forward buttons work.
5. If the component is generic such as app-form, then the task also needs prop parameters such as attributes.


*/


    // {
    //     path: '/test1',
    //     component: require('./components/Example.vue'),
    // },
    // {
    //     path: '/test2',
    //     component: require('./components/Example2.vue'),
    // },
    // {
    //     path: '/oauth',
    //     components: {
    //         content: require('./components/passport/AuthorizedClients.vue'),
    //     },
    // },
    {
        path: '/',
        name: 'Welcome',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            // content: require('./components/app/Content/Welcome.vue'),
            content: () => import(
                /* webpackChunkName: "Welcome" */
                /* webpackPrefetch: true */ 
                './components/app/Content/Welcome.vue'
            ),
        },
    },
    
                
//still testing this..
    // {
    //     path: '/logout',
    //     name: 'Logout',
    //     components: {
    //         // header: require('./components/app/Header.vue'),
    //         // footer: require('./components/app/Footer.vue'),
    //         content: require('./components/app/Content/Login.vue'),
    //     },
    //     beforeEnter: (to, from, next) => {
    //         localStorage.removeItem('user');
    //         next();
    //     }
    // },
    {
        path: '/login',
        name: 'Login',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "Login" */
                './components/app/Content/Login.vue'
            ),
        },
        // beforeEnter: (to, from, next) => {
        //     if (localStorage.getItem('authentication')) {
        //     // if (localStorage.getItem('user')) {
        //         console.log('login found an authentication object in the localstorage');

        //         next({name: 'Dashboard'});
        //         console.log('redirecting to dashboard');
        //     }
        //     else {
        //         next();
        //     }
        // }
    },
    {
        path: '/dashboard/:app',
        name: 'App',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "Dashboard" */
                /* webpackPreload: true */ 
                './components/app/Content/Dashboard.vue'
            ),
        },
        props: {
            content: true,
        },
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('authentication')) {
                next();
            }
            else {
                next({name: 'Login'});
                console.log('redirecting to login');
            }
        },
    },    
    {
        path: '/dashboard',
        name: 'Dashboard',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "Dashboard" */
                /* webpackPreload: true */ 
                './components/app/Content/Dashboard.vue'
            ),
        },
        props: {
            content: true,
        },
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('authentication')) {
                console.log('dashboard found authentication in the localstorage');
                next();
            }
            else {
                next({name: 'Login'});
                console.log('redirecting to login');
            }
        },
    },    
    {
        path: '/abuinstructors',
        name: 'ABUInstructors',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "ABUInstructors" */
                './components/app/Content/ABUInstructors.vue'
            ),
        },
        props: {
            content: true,
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "Profile" */
                /* webpackPrefetch: true */ 
                './components/app/Content/Profile.vue'
            ),
        },
        beforeEnter: (to, from, next) => {           
            if (localStorage.getItem('authentication')) {
                console.log('profile found authentication in the localstorage');
                next();
            }
            else {
                next({name: 'Login'});
                console.log('redirecting to login');
            }
        },
    },
    
    {
        path: '/password/reset/:token',
        name: 'PasswordReset',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "PasswordReset" */
                './components/app/Content/PasswordReset.vue'
            ),
        },
        props: {
            content: true,
        },
    },
    {
        path: '/invitationAccept/:token',
        name: 'InvitationAccept',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "InvitationAccept" */
                './components/app/Content/InvitationAccept.vue'
            ),
        },
        props: {
            content: true,
        },
    },

    {
        path: '/password/request',
        name: 'PasswordRequest',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "PasswordRequest" */
                './components/app/Content/PasswordRequest.vue'
            ),
        },
    },

    {
        path: '/:app/:deployment/:role/:branch/?:inputs',
        name: 'Home',
        components: {
            // header: require('./components/app/Header.vue'),
            // footer: require('./components/app/Footer.vue'),
            content: () => import(
                /* webpackChunkName: "PasswordRequest" */
                './components/app/Content/PasswordRequest.vue'
            ),
        },
    },

];